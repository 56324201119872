import { render, staticRenderFns } from "./share.vue?vue&type=template&id=020c2430&scoped=true&"
import script from "./share.vue?vue&type=script&lang=js&"
export * from "./share.vue?vue&type=script&lang=js&"
import style0 from "./share.vue?vue&type=style&index=0&id=020c2430&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020c2430",
  null
  
)

export default component.exports