<template>
  <div class="module-masterAnalysis">
    <div class="analysis-container" v-show="echartData && echartData.length">
      <div class="analysis-list">
        <div class="analysis-item">
          <p>平均值</p>
          <p class="analysis-value">{{ analysisData.average }}%</p>
        </div>
        <div class="analysis-item">
          <p>最高点</p>
          <p class="analysis-value">{{ analysisData.high }}%</p>
        </div>
        <div class="analysis-item">
          <p>最低点</p>
          <p class="analysis-value">{{ analysisData.low }}%</p>
        </div>
      </div>
      <master-echart :data="echartData" v-if="echartData"></master-echart>
      <div class="day">
        <div class="day-btns">
          <div
            v-for="item in dayBtns"
            :key="item"
            :class="`day-btn${calendarValue === item ? '_active' : ''}`"
            @click="handleDayBtnClick(Number(item))"
          >
            {{ item }}天
          </div>
        </div>
        <div
          :class="`day-btn${
            calendarValue && !Number(calendarValue) ? '_active' : ''
          }`"
          @click="calendarShow = true"
        >
          {{
            calendarValue && !Number(calendarValue) ? calendarValue : '自选时间'
          }}
        </div>
      </div>
      <div class="count">
        <div class="count-item">
          <p class="count-label">
            <span>最高连中次数</span>
            <app-icon
              class="count-icon"
              name="master-masterAnalysis2"
              size="22"
            ></app-icon>
          </p>
          <p class="count-value">{{ countData.continuous }}</p>
        </div>
        <div class="count-item">
          <p class="count-label">
            <span>总命中次数</span>
            <app-icon
              class="count-icon"
              name="master-masterAnalysis1"
              size="22"
            ></app-icon>
          </p>
          <p class="count-value">{{ countData.correct }}</p>
        </div>
      </div>
    </div>
    <div class="analysis-empty" v-show="echartData && !echartData.length">
      <img
        class="empty-img"
        src="~image/lottery/master/masterAnalysis-empty.png"
      />
      <p>该奖师并未发布过直码信息</p>
      <p>暂无分析数据</p>
    </div>
    <van-calendar
      v-model="calendarShow"
      ref="calendar"
      type="range"
      get-container=".page-master"
      :min-date="$dayjs('2021-12-01').toDate()"
      :max-date="$dayjs().subtract(1, 'day').toDate()"
      :default-date="null"
      :max-range="30"
      @confirm="handleCalendarConfirm"
      :formatter="calendarFormatter"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import masterEchart from './masterEchart.vue';

export default {
  components: {
    masterEchart
  },
  props: {
    masterId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      dayBtns: ['7', '10', '30'],
      calendarShow: false,
      calendarValue: '7',
      analysisData: {
        average: 0,
        high: 0,
        low: 0
      },
      countData: {
        continuous: 10,
        correct: 55
      },
      echartData: null
    };
  },
  computed: {
    ...mapState({
      restDateList: state => state.result.restDateList
    })
  },
  watch: {
    calendarShow(val) {
      if (!val) return;
      this.$refs.calendar.scrollToDate(new Date());
    }
  },
  methods: {
    calendarFormatter(day) {
      if (
        this.restDateList.includes(this.$dayjs(day.date).format('YYYY-MM-DD'))
      ) {
        day.topInfo = '休市';
      }
      return day;
    },

    getAnalysisData(data) {
      this.$http('user/callbackRate', data).then(res => {
        this.setAnalysisData(res);
      });
    },

    setAnalysisData(data) {
      let { analysisData, countData } = this;
      analysisData.average = data.callbackLines.rateAvg;
      analysisData.high = data.callbackLines.rateMax;
      analysisData.low = data.callbackLines.rateMin;
      countData.continuous = data.maxCheck;
      countData.correct = data.totalCheck;
      this.echartData = data.callbackLines.rateList.map(e => [e.day, e.rate]);
    },

    getDateList(start, end) {
      let x = this.$dayjs(start);
      let y = this.$dayjs(end);
      let duration = y.diff(x, 'd');
      let dateList = [];
      for (let i = 1; i <= duration - 1; i++) {
        dateList.push(x.add(i, 'd').toDate());
      }
      return [start, ...dateList, end];
    },

    handleDayBtnClick(interval = 7) {
      let data = { userId: this.masterId };
      if (typeof interval === 'number') {
        data.scene = 'days';
        data.days = interval;
        this.calendarValue = `${interval}`;
      }
      if (interval instanceof Array) {
        let [start, end] = interval;
        start = this.$dayjs(start);
        end = this.$dayjs(end);
        data.scene = 'between';
        data.startDate = start.format('YYYY-MM-DD');
        data.endDate = end.format('YYYY-MM-DD');
        this.calendarValue = `${start.format('YYYY.MM.DD')}—${end.format(
          'YYYY.MM.DD'
        )}`;
      }
      this.getAnalysisData(data);
    },

    handleCalendarConfirm(date) {
      this.calendarShow = false;
      this.handleDayBtnClick(date);
    }
  },
  mounted() {
    this.handleDayBtnClick();
  }
};
</script>

<style lang="scss" scoped>
.module-masterAnalysis {
  padding: 30px 0;
  width: 690px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(141, 141, 141, 0.2);
}

.analysis {
  &-list {
    @include flex-between-center;
    padding: 0 30px 10px;
    width: 100%;
    border-radius: 20px 20px 0 0;
    background: #fff;
  }

  &-item {
    flex: 1;
    font-size: 26px;
    color: #a7a7a7;
  }

  &-value {
    margin-top: 9px;
    font-size: 48px;
    color: #1e1e1e;
  }

  &-empty {
    @include flex-center-center;
    padding: 39px 0 29px;
    flex-direction: column;
    font-size: 32px;
    color: #8e8e8e;

    .empty {
      &-img {
        margin-bottom: 60px;
        width: 404px;
        height: 404px;
      }
    }
  }
}

.day {
  @include flex-between-center;
  margin-bottom: 30px;
  padding: 0 30px;

  &-btns {
    @include flex-between-center;
    width: 302px;
  }

  &-btn {
    @include flex-center-center;
    padding: 0 18px;
    min-width: 94px;
    height: 48px;
    border-radius: 16px;
    color: 26px;
    color: #969696;

    &_active {
      @extend .day-btn;
      color: #fff;
      background: linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
    }
  }
}

.count {
  @include flex-between-center;
  padding: 0 30px;
  width: 100%;

  &-label {
    @include flex-between-center;
  }

  &-item {
    @include justify-center;
    flex-direction: column;
    padding: 0 30px;
    width: 300px;
    height: 120px;
    border-radius: 16px;
    font-size: 26px;
    color: #757575;
    background: #fff5f8;
  }

  &-value {
    margin-top: 10px;
    font-size: 50px;
    font-weight: 500;
    color: #d80016;
  }
}
</style>
