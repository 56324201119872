<template>
  <div class="module-forecast" v-if="forecastData">
    <forecast-item
      :class="`forecast${forecastData.payStatus ? '_open' : ''}`"
      :lotteryData="forecastData"
    >
      <template v-slot:forecast>
        <div class="forecast-cover" v-if="!forecastData.payStatus">
          <div class="pay-btn" @click="$emit('payClick', forecastData)">
            {{ hasService ? 'VIP查看' : '查看预测码 ¥2' }}
          </div>
          <router-link
            v-if="!hasService"
            class="pay-link"
            to="/user/serviceList"
            >会员全平台随便看</router-link
          >
        </div>
        <div
          class="forecast-shareBtn"
          v-if="
            forecastData.id && forecastData.payStatus && forecastData.buyOfVip
          "
          :style="{
            bottom: forecastData.forecastList.length > 1 ? '5.067vw' : '2.267vw'
          }"
          @click="$emit('shareClick')"
        ></div>
      </template>
    </forecast-item>
  </div>
</template>

<script>
import forecastItem from '@/components/lotteryItem';

export default {
  components: {
    forecastItem
  },
  props: {
    hasService: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shareCoverShow: false,
      forecastData: null
    };
  },
  methods: {
    initialization() {
      this.forecastData = null;
    },

    setForecastData(data) {
      let forecastData = {
        id: data.forecastId || 0,
        forecastType: data.forecastType,
        payStatus: 0,
        forecastList: [],
        date: this.$dayjs(data.lottery.date).add(1, 'day'),
        no: data.lottery.no + 1,
        buyOfVip: data.buyOfVip
      };
      if (!data.forecastId) {
        forecastData.payStatus = 1;
        forecastData.forecastStatus = 2;
        this.forecastData = forecastData;
        return;
      }
      if (forecastData.forecastType === 10) {
        forecastData.forecastList = [
          data.forecast ? data.forecast.position1 : '000000',
          data.forecast ? data.forecast.position2 : '000000',
          data.forecast ? data.forecast.position3 : '000000',
          data.forecast ? data.forecast.position4 : '000000',
          data.forecast ? data.forecast.position5 : '000000'
        ];
      } else {
        forecastData.forecastList = data.forecast
          ? data.forecast.mapArray
          : ['000000'];
        if (forecastData.forecastType < 10) {
          forecastData.forecastList = forecastData.forecastList.concat([
            '000000',
            '000000',
            '000000',
            '000000'
          ]);
        }
      }
      if (!data.forecast) {
        forecastData.forecastStatus = 0;
        this.forecastData = forecastData;
        return;
      }
      forecastData = {
        ...forecastData,
        checkNum: data.forecast.checkNum,
        rightNumber: data.forecast.rightNumber,
        date: data.forecast.date,
        payStatus: 1,
        forecastStatus: data.forecast.status
      };
      this.forecastData = forecastData;
    }
  }
};
</script>

<style lang="scss" scoped>
.forecast {
  ::v-deep .forecast-container {
    min-height: 157px;
    filter: blur(10px);
    transition: filter 3s linear;
  }

  &_open {
    ::v-deep .forecast-container {
      filter: blur(0);
    }

    .forecast-cover {
      animation: hideForecastCover 3s linear forwards;
    }
  }

  &-cover {
    @include flex-center-center;
    flex-direction: column;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 0 16px 16px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 227, 169, 0.5) 100%
    );

    .pay {
      &-btn {
        @include flex-center-center;
        width: 244px;
        height: 60px;
        border-radius: 24px;
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        background: #fb9938 linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
        box-shadow: 0px 8px 0px 0px #ffaf96;
      }

      &-link {
        @include align-center;
        @include arrow(16px, right, #f30014);
        margin-top: 20px;
        font-size: 26px;
        color: #f30014;

        &::after {
          margin: 2px 0 0;
        }
      }
    }
  }

  &-shareBtn {
    @include flex-center-center;
    z-index: 4;
    position: absolute;
    top: 50%;
    right: 0;
    width: 164px;
    height: 164px;
    background: url(~image/lottery/master/share-btn.png) 0 0 / cover no-repeat;
    transform: translateY(-50%);
  }
}

@keyframes hideForecastCover {
  0% {
    z-index: 4;
    opacity: 1;
  }
  50% {
    z-index: 4;
    opacity: 0.5;
  }
  99% {
    opacity: 0;
  }
  100% {
    z-index: -1;
  }
}
</style>
