<template>
  <van-list
    class="module-lotteryList"
    v-model="lotteryListLoading"
    :error.sync="lotteryListError"
    :finished="lotteryListFinished"
    error-text="请求失败，点击重新加载"
    finished-text="没有更多数据了"
    :immediate-check="false"
    @load="handleLotteryListLoad"
  >
    <div v-show="lotteryList.length"><slot></slot></div>
    <lottery-item
      class="lottery-item"
      v-for="item in lotteryList"
      :key="item.id"
      :lotteryData="item"
    ></lottery-item>
  </van-list>
</template>

<script>
import lotteryItem from '@/components/lotteryItem';

export default {
  components: {
    lotteryItem
  },
  props: {
    masterId: {
      type: [Number, String],
      default: 0
    },
    forecastType: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      initialized: false,
      lotteryList: [],
      lotteryListCurPage: 1,
      lotteryListLastPage: 0,
      lotteryListLoading: false,
      lotteryListError: false,
      lotteryListFinished: false
    };
  },
  methods: {
    initialization() {
      this.initialized = false;
      this.lotteryList = [];
      this.lotteryListCurPage = 1;
      this.lotteryListLastPage = 0;
      this.lotteryListLoading = false;
      this.lotteryListError = false;
      this.lotteryListFinished = false;
    },

    getLotteryList(config = {}) {
      if (
        this.lotteryListLastPage &&
        this.lotteryListCurPage > this.lotteryListLastPage
      ) {
        return;
      }
      return this.$http(
        'user/predictList',
        {
          userId: this.masterId,
          pageSize: 5,
          page: this.lotteryListCurPage,
          type: this.forecastType
        },
        config
      )
        .then(res => {
          if (!this.initialized && !res.data.length) {
            return (this.lotteryListFinished = true);
          }
          this.setLotteryList(res.data);
          this.lotteryListLastPage = res.lastPage;
          this.lotteryListCurPage += 1;
          this.lotteryListFinished = res.currentPage === res.lastPage;
          this.$nextTick(() => {
            this.initialized = true;
          });
        })
        .catch(err => {
          this.lotteryListError = true;
          throw new Error(err);
        })
        .finally(() => (this.lotteryListLoading = false));
    },

    setLotteryList(data) {
      data.forEach(e => {
        let { forecast } = e;
        let obj = {
          id: e.id,
          forecastId: forecast ? forecast.id : 0,
          no: e.no,
          checkNum: forecast ? forecast.checkNum : 0,
          date: e.date,
          forecastList: [],
          forecastStatus: forecast ? forecast.status : 2,
          forecastType: this.forecastType,
          rightNumber: e.rightNumber
        };
        if (!forecast) {
          obj.forecastList = [];
        } else if (obj.forecastType === 10) {
          obj.forecastList = [
            forecast.position1,
            forecast.position2,
            forecast.position3,
            forecast.position4,
            forecast.position5
          ];
        } else {
          obj.forecastList = forecast.mapArray;
        }
        this.lotteryList.push(obj);
      });
    },

    handleLotteryListLoad() {
      if (!this.initialized) return;
      this.getLotteryList();
    }
  }
};
</script>

<style lang="scss" scoped>
.module-lotteryList {
  padding: 0 30px;
  min-height: 50vh;
  background: #fff;
}

.lottery {
  &-item {
    margin: 0 auto 40px;
    width: 690px;
  }
}
</style>
