<template>
  <div class="module-masterInfo" v-if="masterData">
    <app-icon
      class="master-face"
      name="touxiang"
      size="98"
      v-show="!masterData.face"
    ></app-icon>
    <img
      class="master-face"
      v-show="masterData.face"
      :src="masterData.face"
      alt="奖师头像"
    />
    <div class="master-content">
      <p class="master-name">{{ masterData.name }}</p>
      <p class="master-info">
        (10中{{ masterData.count }}，连中{{ masterData.continuous }})
      </p>
    </div>
    <div
      :class="`follow-btn${masterData.isFollow ? '_isFollow' : ''}`"
      @click="handleFollowClick"
    >
      {{ masterData.isFollow ? '取关' : '关注' }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterData: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  methods: {
    async handleFollowClick() {
      this.$http(
        'followers/toggleFollow',
        this.$util.transformKey({
          followingId: this.masterData.id
        })
      ).then(() => {
        this.$emit('update:masterData', {
          ...this.masterData,
          isFollow: !this.masterData.isFollow
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterInfo {
  @include align-center;
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  height: 151px;
  background: url(~image/lottery/master/master-bg.png) 0 0 / cover no-repeat;
}

.master {
  &-face {
    flex: 0 0 auto;
    margin-right: 30px;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  &-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  &-name {
    @include text-ellipsis;
    margin-right: 20px;
    max-width: 204px;
    font-size: 34px;
    font-weight: 500;
    color: #fff;
  }

  &-info {
    margin-right: 30px;
    font-size: 32px;
    font-weight: 500;
    color: #fff2c6;
  }
}

.follow-btn {
  @include flex-center-center;
  margin-top: 11px;
  width: 100px;
  height: 58px;
  border-radius: 24px;
  font-size: 30px;
  font-weight: 500;
  color: #e91400;
  background: linear-gradient(180deg, #fff9e4 0%, #fedb73 100%);
  box-shadow: 0px 4px 0px 0px #d92800;

  &_isFollow {
    @extend .follow-btn;
  }
}
</style>
