<template>
  <div class="module-masterTabs">
    <div
      :class="`tab${value === 1 ? '_active' : ''}`"
      @click="handleTabClick(1)"
    >
      <app-icon
        class="tab-icon_tab1"
        :name="`master-tab1`"
        :color="`${value === 1 ? '#fff' : '#8A8A8A'}`"
        size="52"
      ></app-icon>
      <span>预测信息</span>
    </div>
    <div
      :class="`tab${value === 2 ? '_active' : ''}`"
      @click="handleTabClick(2)"
    >
      <app-icon
        class="tab-icon_tab2"
        :name="`master-tab2`"
        :color="`${value === 2 ? '#fff' : '#8A8A8A'}`"
        size="46"
      ></app-icon>
      <span>回报分析</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    handleTabClick(val) {
      if (val === this.value) return;
      this.$emit('update:value', val);
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterTabs {
  @include flex-between-center;
  z-index: 4;
  position: fixed;
  top: 151px;
  left: 0;
  padding: 30px 30px 0;
  width: 100%;
  height: 128px;
  background: #fff;

  .tab {
    @include flex-center-center;
    width: 328px;
    height: 98px;
    border: 2px solid #ff182f;
    border-radius: 16px;
    font-size: 32px;
    color: #8a8a8a;
    background: #fff;

    &_active {
      @extend .tab;
      border: none;
      color: #fff;
      background: #fb9938 linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
    }

    &-icon {
      &_tab1 {
        margin-top: -6px;
      }

      &_tab2 {
        margin-right: 12px;
      }
    }
  }
}
</style>
