<template>
  <div class="page-share">
    <img class="share-logo" src="~image/lottery/share/logo.png" />
    <img class="share-bg" src="~image/lottery/share/bg.png" />
    <lottery-item class="share-lottery" :lotteryData="forecastData">
      <template v-slot:master>
        <div class="share-master">
          <img
            class="master-face"
            :src="masterData.face"
            v-if="masterData.face"
          />
          <app-icon
            name="touxiang"
            size="98"
            class="master-face"
            v-else
          ></app-icon>
          <p class="master-name">{{ masterData.name }}</p>
          <p class="master-count">
            ({{ `10中${masterData.count} 连中${masterData.continuous}` }})
          </p>
        </div>
      </template>
    </lottery-item>
    <img class="share-cover" src="~image/lottery/share/cover.png" />
  </div>
</template>

<script>
import lotteryItem from '@/components/lotteryItem';

export default {
  components: {
    lotteryItem
  },
  data() {
    return {
      shareData: null,
      forecastData: null,
      masterData: null
    };
  },
  methods: {
    getForecastData() {
      this.$http('predict/share', {
        shareId: this.shareData.sharerId,
        type: this.shareData.forecastType,
        predictId: this.shareData.forecastId
      }).then(res => {
        this.setForecastData(res);
        this.setMasterData(res);
      });
    },

    setForecastData(data) {
      this.forecastData = {
        id: data.id,
        no: data.no,
        date: data.date,
        forecastList:
          !data.type || data.type === 10
            ? [
                data.position1,
                data.position2,
                data.position3,
                data.position4,
                data.position5
              ]
            : JSON.parse(data.map),
        forecastType: data.type || 10,
        forecastStatus: 0,
        rightNumber: ''
      };
    },

    setMasterData(data) {
      this.masterData = {
        id: data.user.id,
        name: data.user.nickName || `用户${data.user.id}`,
        face: data.user.avatarUrl,
        count: data.count,
        continuous: data.continuous
      };
    }
  },
  created() {
    this.$wx.hideMenuItemsAboutShare();
    let { query } = this.$route;
    this.shareData = {
      sharerId: query.sharerId,
      forecastType: query.forecastType,
      forecastId: query.forecastId
    };
    this.getForecastData();
  }
};
</script>

<style lang="scss" scoped>
.page-share {
  padding: 481px 0 0;
  min-height: 100vh;
  background: #c80628;
}

.share {
  &-logo {
    z-index: 3;
    position: absolute;
    top: 31px;
    left: 305px;
    width: 140px;
    height: 62px;
  }

  &-bg {
    z-index: 2;
    position: absolute;
    top: 89px;
    left: 32px;
    width: 710px;
    height: 429px;
  }

  &-lottery {
    z-index: 1;
    margin: 0 auto;
    padding-bottom: 178px;
    width: 690px;

    ::v-deep {
      .lottery-forecast {
        height: 382px;
      }
    }
  }

  &-master {
    @include align-center;
    z-index: 2;
    padding: 0 $lotteryItem-paddingX;
    height: 192px;
    border-radius: 0 0 16px 16px;
    font-size: 34px;
    font-weight: 600;
    color: #2e2e2e;
    background: #fff;

    .master {
      &-face {
        margin-right: 30px;
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }

      &-count {
        margin-left: 20px;
        color: #ff1e32;
      }
    }
  }

  &-cover {
    z-index: 4;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
