<template>
  <van-overlay
    z-index="100"
    :show="show"
    @click="$emit('update:show', false)"
    class="module-shareCover"
  >
    <img class="share-img" src="~image/lottery/master/share-img.png" />
    <p class="share-text">请点击右上角“···”进行分享</p>
  </van-overlay>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.share {
  &-img {
    position: absolute;
    top: 10px;
    right: 28px;
    width: 239px;
    height: 465px;
  }

  &-text {
    position: absolute;
    top: 520px;
    right: 0;
    width: 100vw;
    text-align: center;
    font-size: 38px;
    font-weight: 500;
    color: #fff;
  }
}
</style>
